/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
import * as mutations from "./mutations";
import * as actions from "./actions";
import * as getters from "./getters";
import { fastRandom } from "@/utils/fastRamdom";

const crmMedicFormModule = {
  namespaced: true,
  state: (): any => {
    return {
      states: [],
      loadingStates: false,
      educationLevels: [],
      loadingEducationLevels: false,
      medicalLocations: [],
      loadingMedicalLocations: false,
      patients: [],
      reqrange: { limit: 100, offset: 0 },
      havecard: false,
      epaycard: false,
      unreadDoc: false,
      countUnread: 0,
      loadingPatiens: false,
      doctors: [],
      agents: [],
      loadingDoctors: false,
      loading: false,
      totalpatient: 0,
      showDetails: false,
      patientDetail: null,
      quotes: [],
      salesAccessories: [],
      procedure: null,
      headersPatients: [
        { text: "Fullname", show: true, value: "fullname", sortable: true },
        {
          text: "Policy Number",
          show: false,
          value: "polizeNumber",
          sortable: true,
        },
        { text: "Phone", show: true, value: "homePhone", sortable: true },
        {
          text: "Agent",
          show: true,
          value: "relations",
          width: 150,
          sortable: false,
        },
        { text: "Company", show: true, value: "company", sortable: false },
        {
          text: "Company State",
          show: true,
          value: "companyState",
          sortable: false,
        },
        { text: "Source", show: true, value: "sourceNom", sortable: false },
       
        {
          text: "Quote Date",
          show: false,
          value: "quotedDate",
          sortable: false,
          align: "center",
        },
        {
          text: "Members",
          show: true,
          value: "totalMembers",
          align: "center",
          sortable: false,
        },
        {
          text: "Npn",
          show: false,
          value: "npn",
          align: "center",
          sortable: false,
        },
        { text: "Status", show: true, value: "polizeStatus", sortable: false },
        { text: "With Check", show: false, value: "call", sortable: false },
        { text: "Quote", show: false, value: "actions", sortable: false },
        { text: "Renewal Status", show: false, value: "renewalStatusNom",  width: 120, sortable: false },
        { text: "Tags", show: true, value: "patientTagsNom", sortable: false },
        /*   {
          text: "Gender",
          show: false,
          value: "isMale",
          sortable: false,
        },
        {
          text: "Email",
          value: "email",
          show: false,
          align: "start",
          sortable: false,
        },
        {
          text: "Phone",
          value: "homePhone",
          show: false,
          align: "start",
          sortable: false,
        }, */
      ],
    };
  },
  mutations: mutations,
  actions: actions,
  getters: getters,
};

export default crmMedicFormModule;
